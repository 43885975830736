<template>
  <header class="app-header">
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">
          <img src="http://file.qltrade.ixiaoguo.com.cn/file/4255933719577558.png" />
        </RouterLink>
      </h1>
      <AppHeaderNav />
    </div>
    <div
      class="container"
      style="border: 1px solid #dcdfe6; margin-bottom: 10px"
    >
      <div class="title" style="margin-left: 4px">
        <!-- <a href="javascript:"> -->
        <img :src="require('/static/icons/guapai.png')" />
        <span style="margin-left: 4px; width: 100px">
          挂牌资源&nbsp;&nbsp;|
        </span>
        <!-- </a> -->
      </div>

      <vue-seamless-scroll
        :data="listData"
        :class-option="optionHover"
        class="seamless-warp"
      >
        <ul>
          <li v-for="item in listData" :key="item.date" style="height: 40px">
            {{ item.productName }}(
            <span style="color: #d12b23">{{ item.proStock }}</span>
            )&nbsp;&nbsp;&nbsp;&nbsp;
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
    <div class="line"></div>
  </header>
</template>

<script>
import AppHeaderNav from '@/components/AppHeaderNav'
// import AppHeaderCart from "@/components/AppHeaderCart";
import { toRaw } from 'vue'
export default {
  name: 'AppHeader',
  components: {
    // AppHeaderCart,
    AppHeaderNav,
  },
  computed: {
    optionHover () {
      return {
        // openWatch: true;
        // step: 0.2, // 数值越大速度滚动越快
        //   limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: false, // 是否开启鼠标悬停stop
        //   direction: 1, // 0向下 1向上 2向左 3向右
        //   openWatch: true, // 开启数据实时监控刷新dom
        //   singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        //   singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: 40, // 单行停顿
        waitTime: 2500, // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  data () {
    return {
      listData: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.$store
        .dispatch('AvailabelProduct')
        .then((response) => {
          this.listData = response.result
          this.listData = toRaw(this.listData)
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="less">
.app-header {
  background: #fff;

  .container {
    display: flex;
    align-items: center;
    .title {
      // display: inline-block;
      color: #000;
      height: 40px;
      // width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      //  width: 150px;
      img {
        height: 20px;
      }
    }
  }

  .logo {
    width: 200px;
    img {
      display: block;
      height: 110px;
      width: 100%;
      text-indent: -9999px;
			padding: 10px;
    }
    // a {
    //   display: block;
    //   height: 110px;
    //   width: 100%;
    //   text-indent: -9999px;
    //   background: url(../../static/icons/log.png) no-repeat center 18px /
    //     contain;
    // }
  }
  .search {
    width: 170px;
    height: 32px;
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    line-height: 32px;
    .icon-search {
      font-size: 18px;
      margin-left: 5px;
    }
    input {
      width: 140px;
      padding-left: 5px;
      color: #666;
    }
  }
}

.line {
  height: 4px;
  background: #d12b23;
}
.seamless-warp {
  height: 40px;
  overflow: hidden;
  margin-left: 8px;
  ul {
    display: flex;
    flex-wrap: wrap;
    // padding: 0 5px;
    li {
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>
