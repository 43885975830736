<template>
  <RouterLink :to="`/goods/${goods.id}`" class="goods-item">
    <img :src="goods.img" :alt="goods.name" />
    <p class="name ellipsis">
      {{ goods.productName }}
    </p>
    <p class="desc ellipsis">
      材质: {{ goods.material }}&nbsp; 规格: {{ goods.formate }}
    </p>
    <!-- <div class="lable"></div> -->
    <div v-if="goods.tradeType == '1'" class="lable">全款</div>
    <div v-else class="lable2">保证金</div>
    <div>
      <Row>
        <Col span="16">
				<div class="price">
					 <Numeral
                :value="goods.activePrice"
                format="0,0.00"
              >
                <template #prefix>
                  <strong>¥</strong>
                </template>
              </Numeral>
              /{{  goods.unitName }}
				</div>

          <!-- <p class="price">&yen;{{ goods.activePrice }}/{{ goods.unitName }}</p> -->
        </Col>
        <Col span="8">
          <div class="buy">

						<Button style="color: #d12b23;">立即购买</Button>
					</div>
        </Col>
      </Row>
    </div>

    <div class="line"></div>
    <div>
      <Row>
        <Col span="8">
          <p class="desc">{{ goods.sendFromCityCode }}</p>
        </Col>
        <Col span="5" offset="11">
          <p class="desc">库存{{ goods.currentInventory }}</p>
        </Col>
      </Row>
    </div>
  </RouterLink>
</template>

<script>
export default {
  name: 'GoodsItem',
  props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {}
  },
}
</script>

<style scoped lang="less">
.goods-item {
  display: block;
  width: 280px;
  min-height: 100%;
  padding: 10px 10px;
  // text-align: center;
  .hoverShadow();
  img {
    width: 260px;
    height: 260px;
  }
  p {
    padding-top: 10px;
  }
  .name {
    font-size: 14px;
    font-weight: 550;
  }
  .line {
    height: 1px;
    background: #dcdfe6;
  }
  .lable {
    background: #fff4e3;
    font-size: 11px;
    color: #dc9100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 4px;
    padding: 2px;
    width: 40px;
    height: 22px;
  }
  .lable2 {
    background: #ffeae9;
    font-size: 11px;
    color: #d12b23;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 4px;
    padding: 2px;
    width: 40px;
    height: 22px;
  }
  .desc {
    color: #999;
    font-size: 12px;
    height: 29px;
  }
  .price {
    color: @priceColor;
    font-size: 18px;
		margin-top: 6px;
		margin-bottom: 6px;
  }
  .buy {
    color: @priceColor;
    font-weight: 500;
    font-size: 17px;
    display: flex;
    height: 100%;
		// border:1px solid #ddd;
			// margin-bottom: 6px;
		margin-right: 1px;
    justify-content: right;
    align-items: center;
  }
}
</style>
